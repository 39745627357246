import React, { useState } from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../utils/theme";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  justify-content: center;
  position: relative; /* Needed for eye icon positioning */

  &.width400 {
    width: 400px;
  }
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  color: ${theme.color.sec5};
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const Inp = styled.input`
  margin: 0;
  padding: 10px 10px;
  border-radius: 8px;
  border: 1px solid lightgray;
  font-size: 16px;
  font-weight: 300;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  height: 36px;
  outline: 0;
  width: 100%;
  background-color: ${theme.color.sec9};
  color: ${theme.color.sec1};
  box-shadow: inset 0px 0px 4px 0px rgba(66, 68, 90, 0.2);

  &:autofill {
    box-shadow: inset 0px 0px 4px 0px rgba(66, 68, 90, 0.2);
    color: ${theme.color.sec3};
  }

  &:-webkit-autofill {
    box-shadow: inset 0px 0px 4px 0px rgba(66, 68, 90, 0.2);
    color: ${theme.color.sec3};
  }

  &.white {
    background-color: white;
    box-shadow: inset 0px 0px 6px 1px rgba(66, 68, 90, 0.2);
  }
`;

const EyeIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 38px;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${theme.color.sec5};
    width: 20px;
    height: 20px;
  }
`;

const Input = ({
  label_text,
  label_for,
  type,
  value,
  id,
  name,
  maxLength,
  mb,
  mt,
  onChange,
  className,
  readOnly,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const isPasswordType = type === "password";

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputWrapper mt={mt} mb={mb} className={className}>
      <Label htmlFor={label_for}>{label_text}</Label>
      <Inp
        className={className}
        type={isPasswordType && showPassword ? "text" : type}
        value={value}
        id={id}
        name={name}
        maxLength={maxLength}
        onChange={onChange}
        readOnly={readOnly}
      />
      {isPasswordType && (
        <EyeIcon onClick={togglePasswordVisibility}>
          {showPassword ? (
            // "Eye Slash" icon (password visible)
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 4.5c-7.06 0-10.78 7.17-10.93 7.45a.75.75 0 0 0 0 .6C1.22 12.83 5 20 12 20s10.78-7.17 10.93-7.45a.75.75 0 0 0 0-.6C22.78 11.67 19 4.5 12 4.5zm0 1.5c5.43 0 8.65 5.39 9.41 6.75-.76 1.36-3.98 6.75-9.41 6.75s-8.65-5.39-9.41-6.75C3.35 11.39 6.57 6 12 6zm0 2.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5zM12 9a2.25 2.25 0 1 1 0 4.5A2.25 2.25 0 0 1 12 9z" />
              <path d="M3.53 3.53a.75.75 0 1 0-1.06 1.06l17.25 17.25a.75.75 0 1 0 1.06-1.06L3.53 3.53z" />
            </svg>
          ) : (
            // "Eye" icon (password hidden)
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 4.5c-7.06 0-10.78 7.17-10.93 7.45a.75.75 0 0 0 0 .6C1.22 12.83 5 20 12 20s10.78-7.17 10.93-7.45a.75.75 0 0 0 0-.6C22.78 11.67 19 4.5 12 4.5zm0 1.5c5.43 0 8.65 5.39 9.41 6.75-.76 1.36-3.98 6.75-9.41 6.75s-8.65-5.39-9.41-6.75C3.35 11.39 6.57 6 12 6zm0 2.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5z" />
            </svg>
          )}
        </EyeIcon>
      )}
    </InputWrapper>
  );
};

export default Input;
